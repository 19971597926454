import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/xampp/htdocs/hausman/app/src/client/template.js";
import SymbolSvg from "../assets/images/site-branding/symbol.inline.svg";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnimateIntroBanner = makeShortcode("AnimateIntroBanner");
const RowLines = makeShortcode("RowLines");
const Block = makeShortcode("Block");
const Heading = makeShortcode("Heading");
const LearnMore = makeShortcode("LearnMore");
const Paragraph = makeShortcode("Paragraph");
const Row = makeShortcode("Row");
const Navigation = makeShortcode("Navigation");
const Anchor = makeShortcode("Anchor");
const AnimateIntroBlock = makeShortcode("AnimateIntroBlock");
const Decoration = makeShortcode("Decoration");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnimateIntroBanner id="banner" className="background_logo dark" background={<><RowLines mdxType="RowLines" /><SymbolSvg mdxType="SymbolSvg" /></>} mdxType="AnimateIntroBanner">
  <Block mdxType="Block">
    <Heading mdxType="Heading">
      Main<br /> Services
    </Heading>
    <LearnMore elementId="all_services" color="white" mdxType="LearnMore" />
  </Block>
  <Block className="content_block intro_from_below" mdxType="Block">
    <Paragraph mdxType="Paragraph">We offer integrated, individual, or tailored services to property and asset owners to manage, operate, and maintain your complex facilities and industrial camp accommodations.</Paragraph>
  </Block>  
    </AnimateIntroBanner>
    <Row id="all_services" background={<RowLines disableScrollIndicator={true} mdxType="RowLines" />} disableScrollIndicator={true} mdxType="Row">
  <Block className="navigation_block" mdxType="Block">
  <div className="stick_to_container">
  <Heading level={2} mdxType="Heading">Main Services</Heading>
  <Navigation mdxType="Navigation">
  <Anchor elementId="access-security" mdxType="Anchor">Access & Security</Anchor>
  <Anchor elementId="cleaning-laundry" mdxType="Anchor">Cleaning & Laundry</Anchor>
  <Anchor elementId="hospitality-wellness" mdxType="Anchor">Hospitality & Wellness</Anchor>
  <Anchor elementId="hygiene-pest-control" mdxType="Anchor">Hygiene & Pest Control</Anchor>
  <Anchor elementId="industrial-accommodation" mdxType="Anchor">Industrial Accommodation</Anchor>
  <Anchor elementId="information-management" mdxType="Anchor">Information Management</Anchor>
  <Anchor elementId="landscaping" mdxType="Anchor">Landscaping</Anchor>
  <Anchor elementId="maintenance" mdxType="Anchor">Maintenance</Anchor>
  <Anchor elementId="operations-management" mdxType="Anchor">Operations & Management</Anchor>
  <Anchor elementId="property-management" mdxType="Anchor">Property Management</Anchor>
  <Anchor elementId="quality-assurance-control" mdxType="Anchor">Quality Assurance & Control</Anchor>
  <Anchor elementId="training" mdxType="Anchor">Training</Anchor>  
  <Anchor elementId="utility-management" mdxType="Anchor">Utility Management</Anchor>
  </Navigation>  
  </div>
  </Block>
  <AnimateIntroBlock id="access-security" className="content_block" mdxType="AnimateIntroBlock">
    <header>
    <Decoration from="services/access-security" mdxType="Decoration" />
    <Heading level={2} className="title" mdxType="Heading">Access & Security</Heading>
    </header>
    <div className="content">
    <Paragraph mdxType="Paragraph">We provide security services and access control using advanced methodologies such as Physical Security Information Management (PSIM) software, license plate recognition, CCTV command & control room software, X-Ray security scanners, and strict gate pass procedures among others.</Paragraph>
    </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock id="cleaning-laundry" className="content_block" mdxType="AnimateIntroBlock">
    <header>
    <Decoration from="services/cleaning-laundry" mdxType="Decoration" />
    <Heading level={2} className="title" mdxType="Heading">Cleaning & Laundry</Heading>
    </header>
    <div className="content">
    <Paragraph mdxType="Paragraph">Our Cleaning and Laundry services help maintain your property in excellent condition and ensure proper functioning of your facility. Our main services include:</Paragraph>
    <Paragraph mdxType="Paragraph">
    <ul>
    <li>Sweeping</li>
    <li>Scrubbing</li>
    <li>Crystallization</li>
    <li>Buffing</li>
    <li>Facade Cleaning</li>
    <li>Deep Cleaning</li>
    <li>Liquid Nitrogen Blasting</li>
    <li>Vapor Cleaning</li>
    <li>Laundry Management & Identification</li>
    </ul>
    </Paragraph>
    </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock id="hospitality-wellness" className="content_block" mdxType="AnimateIntroBlock">
    <header>
    <Decoration from="services/hospitality-wellness" mdxType="Decoration" />
    <Heading level={2} className="title" mdxType="Heading">Hospitality & Wellness</Heading>
    </header>
    <div className="content">
    <Paragraph mdxType="Paragraph">When it comes to Facility Management (FM), the standard should be no less than a five-star hospitality experience. </Paragraph>
    <Paragraph mdxType="Paragraph">Our FM team practically acts as a gatekeeper of the client’s brand to reinforce the value of their property. Our complementary services include: </Paragraph>
    <Paragraph mdxType="Paragraph">
    <ul>
    <li>Providing Hotel Services: Receptionist, Bellboy, Front Desk</li>
    <li>Catering, Mini Market, Event Management, Entertainment Facilities</li>
    <li>Personal Trainers, Lifeguards and Spa Services for Wellness centers</li>
    </ul>
    </Paragraph>
    </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock id="hygiene-pest-control" className="content_block" mdxType="AnimateIntroBlock">
    <header>
    <Decoration from="services/hygiene-pest-control" mdxType="Decoration" />
    <Heading level={2} className="title" mdxType="Heading">Hygiene & Pest Control</Heading>
    </header>
    <div className="content">
    <Paragraph mdxType="Paragraph">Our Hygiene and Pest Control services aim for the utmost health, safety and hygiene standards, to make the workplace or environment safe and pleasant for everyone. We use an eco-friendly, non-toxic air and surface disinfection product which:</Paragraph>
    <Paragraph mdxType="Paragraph">
    <ul>
    <li>Kills 99.99% of all known microorganisms and biofilms </li>
    <li>Permeates extensively throughout the targeted areas and disinfects on contact</li>
    <li>Conforms to high safety standards for workers</li>
    <li>Can be applied on all types of surfaces</li>
    <li>Leaves no stain or residue</li>
    <li>Is non-irritating</li>
    <li>Contains no harmful chlorates</li>
    </ul>
    </Paragraph>
    </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock id="industrial-accommodation" className="content_block" mdxType="AnimateIntroBlock">
    <header>
    <Decoration from="services/industrial-accommodation" mdxType="Decoration" />
    <Heading level={2} className="title" mdxType="Heading">Industrial Accommodation</Heading>
    </header>
    <div className="content">
    <Paragraph mdxType="Paragraph">Many developments come with a need to secure the accommodation, health, and safety of large numbers of people coming from various corners of the world. In compliance with the different cultures involved and the client’s rules and regulations, Hausman can manage accommodations and facilities for developments of any type and size, and at various stages within a project. This includes:</Paragraph>
    <Paragraph mdxType="Paragraph">
    <ul>
    <li>Temporary Camps</li>
    <li>Construction Worker Camps</li>
    <li>Permanent Dormitories</li>
    <li>Refugee Camps</li>
    <li>Military Camps</li>
    <li>Oil & Gas Camps</li>
    <li>Prisons</li>
    </ul>
    </Paragraph>
    </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock id="information-management" className="content_block" mdxType="AnimateIntroBlock">
    <header>
    <Decoration from="services/information-management" mdxType="Decoration" />
    <Heading level={2} className="title" mdxType="Heading">Information Management</Heading>
    </header>
    <div className="content">
    <Paragraph mdxType="Paragraph">We don't just document everything we do for you. We also help keep the records you receive from everyone else organized for fast, effective, and optimized decision-making. Hausman can handle the interface between the construction side and the management, as well as gather, sort, and catalogue the client’s:</Paragraph>
    <Paragraph mdxType="Paragraph">
    <ul>
    <li>Drawings</li>
    <li>Manuals</li>
    <li>Warranties</li>
    <li>Available Spare Parts</li>
    <li>Defect Liability Periods</li>
    </ul>
    </Paragraph>
    <Paragraph mdxType="Paragraph">This task can be accompanied by specialized training, document and contact management, along with a thorough review of all processes through an Integrated Management System.</Paragraph>
    </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock id="landscaping" className="content_block" mdxType="AnimateIntroBlock">
    <header>
    <Decoration from="services/landscaping" mdxType="Decoration" />
    <Heading level={2} className="title" mdxType="Heading">Landscaping</Heading>
    </header>
    <div className="content">
    <Paragraph mdxType="Paragraph">We can maintain the landscape of a wide range of facilities and properties. We also provide proper irrigation management to reduce wasted water and ensure cleaner and healthier gardens, parks, and plantations.</Paragraph>
    </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock id="maintenance" className="content_block" mdxType="AnimateIntroBlock">
    <header>
    <Decoration from="services/maintenance" mdxType="Decoration" />
    <Heading level={2} className="title" mdxType="Heading">Maintenance</Heading>
    </header>
    <div className="content">
    <Paragraph mdxType="Paragraph">While our corrective maintenance activities aim to quickly repair or replace a piece of failed equipment, we can add more value with planned maintenance to prevent breakdowns from happening in the first place.</Paragraph>
    <Paragraph mdxType="Paragraph">Hausman combines two types of planned maintenance: </Paragraph>
    <Paragraph mdxType="Paragraph">
    <ul>
    <li>Preventive Maintenance through regular check-ups</li>
    <li>Predictive Maintenance through the continuous monitoring of the equipment, using specialized tools to ensure its proper functioning at all times, and effectively help prolong its lifespan</li>
    </ul>
    </Paragraph>
    </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock id="operations-management" className="content_block" mdxType="AnimateIntroBlock">
    <header>
    <Decoration from="services/operations-management" mdxType="Decoration" />
    <Heading level={2} className="title" mdxType="Heading">Operations & Management</Heading>
    </header>
    <div className="content">
    <Paragraph mdxType="Paragraph">For every project, the Hausman team aims for the highest quality standards and your complete satisfaction, while keeping an eye on safety, schedule, and cost-effectiveness. This is achieved through careful assessments, diligent applications, and strong coordination between all entities.</Paragraph>
    <Paragraph mdxType="Paragraph">Our Operations & Management services include, among others:</Paragraph>
    <Paragraph mdxType="Paragraph">
    <ul>
    <li>Subcontractor Management</li>
    <li>Procurement Management</li>
    <li>Spare Parts Management</li>
    <li>Major Renovation Projects</li>
    <li>Regular Monitoring & Reporting</li>
    </ul>
    </Paragraph>
    </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock id="property-management" className="content_block" mdxType="AnimateIntroBlock">
    <header>
    <Decoration from="services/property-management" mdxType="Decoration" />
    <Heading level={2} className="title" mdxType="Heading">Property Management</Heading>
    </header>
    <div className="content">
    <Paragraph mdxType="Paragraph">The key to providing flawless property management is to truly understand what the client and end users' expectations are, and what is critical from a Facility Management perspective.</Paragraph>
    <Paragraph mdxType="Paragraph">Our approach tackles multiple layers of property management:</Paragraph>
    <Paragraph mdxType="Paragraph">
    <ul>
    <li>Preparing the yearly provisional budget for the facility</li>
    <li>Preparing and running the General Assemblies </li>
    <li>Monthly and Quarterly consumption billing</li>
    <li>Collecting and maintaining the client's payments</li>
    <li>Safeguarding the client's legal interests</li>
    <li>Managing quotations and procurement procedures</li>
    <li>Managing tenants</li>
    <li>Managing transport, traffic, and logistics</li>
    </ul>
    </Paragraph>
    </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock id="quality-assurance-control" className="content_block" mdxType="AnimateIntroBlock">
    <header>
    <Decoration from="services/quality-assurance-control" mdxType="Decoration" />
    <Heading level={2} className="title" mdxType="Heading">Quality Assurance & Control</Heading>
    </header>
    <div className="content">
    <Paragraph mdxType="Paragraph">All our tasks are operated and executed within Quality Assurance (QA) and Quality Control (QC) frameworks to continuously improve the quality of our services, and ensure our client’s expectations are met or exceeded.</Paragraph>
    </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock id="training" className="content_block" mdxType="AnimateIntroBlock">
    <header>
    <Decoration from="services/training" mdxType="Decoration" />
    <Heading level={2} className="title" mdxType="Heading">Training</Heading>
    </header>
    <div className="content">
    <Paragraph mdxType="Paragraph">At Hausman, we strive to keep the highest standards through continuous training and skill development. Whenever required, our team undergoes exclusive in-house training sessions developed by subject matter experts. These include:</Paragraph>
    <Paragraph mdxType="Paragraph">
    <ul>
    <li>Specialized System training</li>
    <li>Simulation training</li>
    <li>Toolbox training</li>
    <li>First Aid training</li>
    <li>Firefighting training</li>
    <li>Customer Service training</li>
    <li>IOSH Managing Safely training</li>
    <li>Human Resource Audit</li>
    </ul>
    </Paragraph>
    </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock id="utility-management" className="content_block" mdxType="AnimateIntroBlock">
    <header>
    <Decoration from="services/utility-management" mdxType="Decoration" />
    <Heading level={2} className="title" mdxType="Heading">Utility Management</Heading>
    </header>
    <div className="content">
    <Paragraph mdxType="Paragraph">Our team can develop a monitoring strategy and implement a utility management action plan for water, fuel, gas, and electricity. This can be accompanied by a utility consumption analysis to help you with energy management and cost control.</Paragraph>
    </div>
  </AnimateIntroBlock>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      